<template>
  <div class="provider-service-transport">
    <row-box-skeleton
      v-if="!serviceLoaded"
      :show-top-line="false"
    />
    <div
      v-if="serviceLoaded"
      class="wrap-admin-orders"
    >
      <div
        v-for="(item, index) of providerServiceOrders"
        :key="index"
        class="data-wrapper"
        :class="{ active: activeService && activeService.id === item.id }"
        @click="setActiveObject(item)"
      >
        <p>{{ item.ride_name }}</p>
        <b-form-checkbox
          v-model="item.is_active"
          switch
          inline
        />
      </div>
    </div>
    <div
      v-if="activeService"
      class="row mt-3"
    >
      <div class="col-md-6">
        <b-form-group :label="$t('Vehicle Make')">
          <b-form-input
            v-model="activeService.vehicle_make"
            name="vehicle_make"
            type="text"
          />
        </b-form-group>
      </div>
      <div class="col-md-6">
        <b-form-group :label="$t('Vehicle Model')">
          <b-form-input
            v-model="activeService.vehicle_model"
            name="vehicle_model"
            type="text"
          />
        </b-form-group>
      </div>
      <div class="col-md-6">
        <b-form-group :label="$t('Vehicle Year')">
          <b-form-input
            v-model="activeService.vehicle_year"
            name="vehicle_year"
            type="text"
          />
        </b-form-group>
      </div>
      <div class="col-md-6">
        <b-form-group :label="$t('Vehicle Color')">
          <b-form-input
            v-model="activeService.vehicle_color"
            name="vehicle_color"
            type="text"
          />
        </b-form-group>
      </div>
      <div class="col-md-6">
        <b-form-group :label="$t('Vehicle Type')">
          <b-form-select
            v-model="activeService.vehicle_type"
            :options="transportVehicleTypes"
          />
        </b-form-group>
      </div>
      <div class="col-md-6">
        <b-form-group :label="$t('Vehicle Number')">
          <b-form-input
            v-model="activeService.vehicle_no"
            name="vehicle_no"
            type="text"
          />
        </b-form-group>
      </div>
      <div class="col-md-12">
        <p class="mb-0 d-block w-100">
          {{ $t('Upload Image') }}
        </p>
        <p class="mb-0 small d-block w-100">
          {{ $t('Upload RC And Insurance') }}
        </p>
      </div>
      <div class="col-md-6">
        <image-input
          ref="frontImage"
          :default-image="activeService.picture"
          @input-file="uploadFrontImage"
        />
      </div>
      <div class="col-md-6">
        <image-input
          ref="backImage"
          :default-image="activeService.picture1"
          @input-file="uploadBackImage"
        />
      </div>
      <div class="col-md-12 mt-2 text-center">
        <b-button
          variant="primary"
          @click="makeVehicle"
        >
          {{ $t('Save') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { BFormCheckbox, BFormGroup, BFormInput, BButton, BFormSelect } from 'bootstrap-vue'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import ImageInput from '@/views/users/shared/ImageInput.vue'
import RowBoxSkeleton from '@/@core/layouts/shared/RowBoxSkeleton.vue'
import { useProviderUi } from '../useProvider'

export default {
  components: {
    BFormCheckbox,
    ImageInput,
    BFormGroup,
    BFormInput,
    BButton,
    BFormSelect,
    RowBoxSkeleton,
  },
  data() {
    return {
      providerServiceOrders: [],
      activeService: null,
      transportVehicleTypes: [],
      serviceLoaded: false,
    }
  },
  mounted() {
    this.getServiceOrder(false)
  },
  methods: {
    clearImageUpload() {
      if (this.$refs.frontImage) {
        this.$refs.frontImage.imageData = null
        this.$refs.frontImage.uploadedFile = null
      }
      if (this.$refs.backImage) {
        this.$refs.backImage.imageData = null
        this.$refs.backImage.uploadedFile = null
      }
    },
    uploadFrontImage(payload) {
      this.activeService.pictureBlob = payload
    },
    uploadBackImage(payload) {
      this.activeService.picture1Blob = payload
    },
    setActiveObject(props) {
      this.clearImageUpload()
      this.activeService = {
        vehicle_type: '',
        vehicle_no: '',
        id: '',
        picture: '',
        picture1: '',
        vehicle_make: '',
        vehicle_model: '',
        vehicle_year: '',
        vehicle_color: '',
      }
      this.activeService.id = props.id
      if (props.providerservice && props.providerservice.providervehicle) {
        this.activeService.vehicle_type = props.providerservice.providervehicle.vehicle_service_id
        this.activeService.vehicle_no = props.providerservice.providervehicle.vehicle_no
        this.activeService.picture = props.providerservice.providervehicle.picture
        this.activeService.picture1 = props.providerservice.providervehicle.picture1
        this.activeService.vehicle_make = props.providerservice.providervehicle.vehicle_make
        this.activeService.vehicle_model = props.providerservice.providervehicle.vehicle_model
        this.activeService.vehicle_year = props.providerservice.providervehicle.vehicle_year
        this.activeService.vehicle_color = props.providerservice.providervehicle.vehicle_color
      }
      this.transportVehicleTypes = []
      if (props.servicelist) {
        this.transportVehicleTypes = props.servicelist.map(x => ({
          text: x.vehicle_name,
          value: x.id,
        }))
        this.transportVehicleTypes.unshift({
          text: 'Select Vehicle Type',
          value: '',
        })
      }
    },
    getUploadType() {
      const { providerVehicleAdd, providerVehicleEdit } = useProviderUi()
      const isVehicleExist = this.providerServiceOrders.find(x => x.id === this.activeService.id)
      const formData = new FormData()
      formData.append('vehicle_make', this.activeService.vehicle_make)
      formData.append('admin_service', 'TRANSPORT')
      if (isVehicleExist.providerservice && isVehicleExist.providerservice.providervehicle) {
        formData.append('id', isVehicleExist.providerservice.providervehicle.id)
      } else {
        formData.append('id', '')
      }
      formData.append('category_id', isVehicleExist.id)
      formData.append('vehicle_model', this.activeService.vehicle_model)
      formData.append('vehicle_year', this.activeService.vehicle_year)
      formData.append('vehicle_color', this.activeService.vehicle_color)
      formData.append('vehicle_id', this.activeService.vehicle_type)
      formData.append('vehicle_no', this.activeService.vehicle_no)
      if (this.activeService.pictureBlob) formData.append('picture', this.activeService.pictureBlob)
      if (this.activeService.picture1Blob) formData.append('picture1', this.activeService.picture1Blob)
      if (!isVehicleExist.providerservice) return providerVehicleAdd(formData)
      return providerVehicleEdit(formData)
    },
    makeVehicle() {
      showLoader()
      this.getUploadType()
        .then(({ data }) => {
          hideLoader()
          if (data.statusCode === '200') {
            showSuccessNotification(this, data.message)
            this.getServiceOrder()
          } else {
            showDangerNotification(this, data.message)
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getServiceOrder(loaderShow = true) {
      if (loaderShow) showLoader()
      this.clearImageUpload()
      const { providerRideType } = useProviderUi()
      providerRideType()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.providerServiceOrders = data.responseData
            this.providerServiceOrders.forEach(item => {
              if (item.providerservice) {
                // eslint-disable-next-line no-param-reassign
                item.is_active = true
              } else {
                // eslint-disable-next-line no-param-reassign
                item.is_active = false
              }
            })
            this.serviceLoaded = true
            const isAnyActive = this.providerServiceOrders.find(x => x.is_active)
            if (isAnyActive) this.setActiveObject(isAnyActive)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style scoped>
.wrap-admin-orders {
  max-width: 100%;
  overflow: auto;
  white-space: nowrap;
}

.wrap-admin-orders .data-wrapper {
  width: 20%;
  display: inline-block;
  background: #6e6b7b;
  padding: 35px 15px;
  margin-right: 15px;
  border-radius: 4px;
  color: #fff;
  text-align: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.wrap-admin-orders .data-wrapper.active {
  background: #c8c4f4;
  color: #000;
}

.provider-my-services .base-image-input {
  border: 1px solid #000;
}
</style>
