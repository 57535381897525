<template>
  <div class="provider-services">
    <row-box-skeleton v-if="!serviceLoaded" />
    <div
      v-if="serviceLoaded"
      class="wrap-admin-orders"
    >
      <div class="row mb-1">
        <div class="col-md-3">
          <label>{{ $t('Status') }}</label>
          <b-form-select
            v-model="filterStatus"
            :options="$superApp.appConstants.filterStatusList()"
            @change="filterChanged()"
          />
        </div>
      </div>
      <good-data-table
        ref="serviceGrid"
        :mode="'local'"
        :no-serial="true"
        :columns="serviceColumn"
        :load-local-data="true"
        :chBoxenabled="true"
        :chkBoxAction="{active:true, deactive: true}"
        @enable="enableDisableSelected($event, true)"
        @disable="enableDisableSelected($event, false)"
      />
      <b-modal
        v-model="editService"
        :no-close-on-backdrop="true"
        :title="$t('Edit Service')"
        ok-only
        @ok="saveEditService"
        @hidden="editService = false"
      >
        <div
          v-if="serviceForEdit"
          class="row"
        >
          <div class="col-md-12">
            <b-form-group
              :label="$t('Service Name')"
              class="text-left mt-2"
            >
              <b-form-input
                v-model="serviceForEdit.data.title[serviceForEdit.findId]"
                :disabled="isAdmin(serviceForEdit.data.price_choose[serviceForEdit.findId])"
              />
            </b-form-group>
          </div>
          <div class="col-md-12">
            <b-form-group
              :label="$t('Charges') + ` (${serviceForEdit.data.currency_symbol[serviceForEdit.findId]})`"
              class="text-left mt-2"
            >
              <b-form-input
                v-model="serviceForEdit.data.price[serviceForEdit.findId]"
                :disabled="isAdmin(serviceForEdit.data.price_choose[serviceForEdit.findId])"
              />
            </b-form-group>
          </div>
          <div class="col-md-12">
            <b-form-group
              v-if="serviceForEdit.data.fare_type[serviceForEdit.findId] === 'HOURLY' || serviceForEdit.data.fare_type[serviceForEdit.findId] === 'DISTANCETIME'"
              :label="$t('Minutes Fare') + ` (${serviceForEdit.data.currency_symbol[serviceForEdit.findId]})`"
              class="text-left"
            >
              <b-form-input
                v-model="serviceForEdit.data.per_mile[serviceForEdit.findId]"
                :disabled="isAdmin(serviceForEdit.data.price_choose[serviceForEdit.findId])"
              />
            </b-form-group>
          </div>
          <div class="col-md-12">
            <b-form-group
              v-if="serviceForEdit.data.fare_type[serviceForEdit.findId] === 'DISTANCETIME'"
              :label="$t('Miles Fare') + ` (${serviceForEdit.data.currency_symbol[serviceForEdit.findId]})`"
              class="text-left"
            >
              <b-form-input
                v-model="serviceForEdit.data.per_mile[serviceForEdit.findId]"
                :disabled="isAdmin(serviceForEdit.data.price_choose[serviceForEdit.findId])"
              />
            </b-form-group>
          </div>
          <div class="col-md-12">
            <b-form-group
              :label="$t('Description')"
              class="text-left"
            >
              <b-form-input v-model="serviceForEdit.data.description[serviceForEdit.findId]" />
            </b-form-group>
          </div>
        </div>
      </b-modal>
      <!-- <div
        v-for="(item, index) of providerServiceOrders"
        :key="index"
        class="row"
      >
        <h4 class="w-100 pl-2">
          {{ item.name }}
        </h4>
        <div
          v-for="(service, counter) of item.data.name"
          :key="counter"
          class="col-md-3"
        >
          <div class="data-wrapper">
            <p>{{ service }}</p>
            <b-form-checkbox
              v-model="item.data.service_status[counter]"
              checked
              switch
              inline
            />
            <b-form-group
              :label="$t('Base Fare') + ` (${item.data.currency_symbol[counter]})`"
              class="text-left mt-2"
            >
              <b-form-input
                v-model="item.data.price[counter]"
                :disabled="isAdmin(item.data.price_choose[counter])"
              />
            </b-form-group>
            <b-form-group
              v-if="item.data.fare_type[counter] === 'HOURLY' || item.data.fare_type[counter] === 'DISTANCETIME'"
              :label="$t('Minutes Fare') + ` (${item.data.currency_symbol[counter]})`"
              class="text-left"
            >
              <b-form-input
                v-model="item.data.per_mile[counter]"
                :disabled="isAdmin(item.data.price_choose[counter])"
              />
            </b-form-group>
            <b-form-group
              v-if="item.data.fare_type[counter] === 'DISTANCETIME'"
              :label="$t('Miles Fare') + ` (${item.data.currency_symbol[counter]})`"
              class="text-left"
            >
              <b-form-input
                v-model="item.data.per_mile[counter]"
                :disabled="isAdmin(item.data.price_choose[counter])"
              />
            </b-form-group>
          </div>
        </div>
      </div>
      <div class="col-md-12 mt-2 text-center">
        <b-button
          variant="primary"
          @click="makeVehicle"
        >
          {{ $t('Save') }}
        </b-button>
      </div> -->
    </div>
  </div>
</template>

<script>
import { BFormGroup, BFormInput, BModal, BFormSelect } from 'bootstrap-vue'
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import RowBoxSkeleton from '@/@core/layouts/shared/RowBoxSkeleton.vue'
import { useProviderUi } from '../useProvider'
import { mapActions } from 'vuex'
import { log } from 'pdfmake/build/pdfmake'

export default {
  components: {
    GoodDataTable,
    BFormSelect,
    BModal,
    BFormGroup,
    BFormInput,
    RowBoxSkeleton,
  },
  data() {
    return {
      providerServiceOrders: [],
      serviceForEdit: null,
      editService: false,
      filterStatus: '',
      modifyServiceData: [],
      originalModifiedData: [],
      activeService: null,
      serviceLoaded: false,
      serviceColumn: [
        {
          label: 'Image',
          field: 'picture',
          align: 'center',
          preventExport: true,
          useHtml: true,
          excelHtml: props => props.picture,
          renderer: props => `<img src="${window.SuperApp.helper.image(props.picture)}" onerror="this.src = '${window.SuperApp.constants.noImageAvailable}'" style="width: 40px; height: 40px; border-radius: 10%;" />`,
        },
        {
          label: 'Category',
          field: 'category',
        },
        {
          label: 'Sub Category',
          field: 'sub_category_name',
        },
        {
          label: 'Service',
          field: 'sub_category',
        },
        // {
        //   label: 'Description',
        //   field: 'description',
        // },
        {
          label: 'Base Price',
          field: 'base_price',
          useRenderer: true,
          renderer: props => `${props.currency_symbol} ${props.base_price}`,
        },
        {
          label: 'Status',
          field: 'availability',
          align: 'center',
          excelHtml: props => (props.availability ? 'Available' : 'Unavailable'),
          showToggle: true,
          action: props => {
            this.$swal({
              title: this.$t('You want to change the availability?'),
              iconHtml: this.$helpers.swalIcon('schedule.png'),
              showCancelButton: true,
              confirmButtonText: this.$t('Yes'),
              cancelButtonText: this.$t('No'),
              customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger ml-1',
                icon: 'border-0',
              },
              buttonsStyling: false,
            }).then(result => {
              if (result.value) {
                const isExist = this.providerServiceOrders.find(x => x.name === props.service)
                if (isExist) {
                  const findId = isExist.data.id.findIndex(x => x === props.id)
                  if (findId >= 0) {
                    if (!isExist.data.service_status) {
                      isExist.data.service_status = []
                    }
                    isExist.data.service_status[findId] = !props.availability
                    this.makeVehicle()
                  }
                }
              } else {
                this.$refs.serviceGrid.resetToOriginal()
              }
            })
          },
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          actions: [
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('edit'),
              text: 'Edit',
              action: props => {
                const isExist = this.providerServiceOrders.find(x => x.name === props.service)
                if (isExist) {
                  const findId = isExist.data.id.findIndex(x => x === props.id)
                  if (findId >= 0) {
                    if (!isExist.data.service_status) {
                      isExist.data.service_status = []
                    }
                    this.serviceForEdit = {
                      data: isExist.data,
                      service: props,
                      findId,
                    }
                    this.editService = true
                  }
                }
              },
            },
          ],
        },
      ],
    }
  },
  mounted() {
    this.getServiceOrder(false)
  },
  methods: {
    ...mapActions('providerService', [
      'updateStatus'
    ]),
    enableDisableSelected(data, type){
      this.$swal({
        title: this.$t('You want to change the status?'),
        iconHtml: this.$helpers.swalIcon('schedule.png'),
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No'),
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
          icon: 'border-0',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          const formData = new FormData;
          formData.append('status', type ? 1 : 0)
          formData.append(`ids`, JSON.stringify(data.selectedRows.map(i => i.id)))
          formData.append(`all_data`, JSON.stringify(data.selectedRows))
          await this.updateStatus(formData)
          this.getServiceOrder()
        } else {
          this.$refs.serviceGrid.resetToOriginal()
        }
      })
    },
    filterChanged() {
      if (this.filterStatus === '') {
        this.$refs.serviceGrid.setLocalData(this.originalModifiedData)
      } else if (this.filterStatus === '0') {
        this.$refs.serviceGrid.setLocalData(this.originalModifiedData.filter(x => x.availability === false))
      } else if (this.filterStatus === '1') {
        this.$refs.serviceGrid.setLocalData(this.originalModifiedData.filter(x => x.availability === true))
      }
    },
    saveEditService() {
      const props = this.serviceForEdit.service
      const isExist = this.providerServiceOrders.find(x => x.name === props.service)
      if (isExist) {
        const findId = isExist.data.id.findIndex(x => x === props.id)
        if (findId >= 0) {
          isExist.data = this.serviceForEdit.data
          this.makeVehicle()
        }
      }
    },
    isAdmin(props) {
      return props === 'admin_price' ? 'disabled' : null
    },
    getUploadType() {
      const { providerVehicleEditService } = useProviderUi()
      const formData = new FormData()
      formData.append('admin_service', 'SERVICE')
      let serviceCounter = 0
      this.providerServiceOrders.forEach(item => {
        item.data.name.forEach((element, counter) => {
          if (item.data.service_status[counter]) {
            formData.append(`service_id[${serviceCounter}]`, item.data.id[counter])
          }
          formData.append(`category_id[${serviceCounter}]`, item.data.category_id[counter])
          formData.append(`description[${serviceCounter}]`, item.data.description[counter])
          formData.append(`sub_category_id[${serviceCounter}]`, item.data.sub_category_id[counter])
          formData.append(`base_fare[${serviceCounter}]`, item.data.price[counter])
          formData.append(`title[${serviceCounter}]`, item.data.title[counter])
          if (item.data.fare_type[counter] === 'HOURLY' || item.data.fare_type[counter] === 'DISTANCETIME') formData.append(`per_mins[${serviceCounter}]`, item.data.per_mins[counter])
          if (item.data.fare_type[counter] === 'DISTANCETIME') formData.append(`per_mile[${serviceCounter}]`, item.data.per_mile[counter])
          serviceCounter += 1
        })
      })
      return providerVehicleEditService(formData)
    },
    makeVehicle() {
      showLoader()
      this.getUploadType()
        .then(({ data }) => {
          hideLoader()
          if (data.statusCode === '200') {
            showSuccessNotification(this, data.message)
            this.getServiceOrder()
          } else {
            showDangerNotification(this, data.message)
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getServiceOrder(loaderShow = true) {
      if (loaderShow) showLoader()
      const { providerTotalServices } = useProviderUi()
      providerTotalServices()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.providerServiceOrders = []
            const services = data.responseData
            Object.keys(services).forEach(item => {
              services[item].service_status = []
              services[item].provider_service_id.forEach((x, index) => {
                if (x && services[item].status[index] === 'ACTIVE') {
                  services[item].service_status.push(true)
                } else {
                  services[item].service_status.push(false)
                }
              })
              this.providerServiceOrders.push({
                name: item,
                data: services[item],
              })
            })
            this.serviceLoaded = true
            this.modifyServiceData = []
            this.providerServiceOrders.forEach(item => {

              if (item.data.name) {
                item.data.name.forEach((name, counter) => {
                  if (item.data.status[counter] === 'ACTIVE' || item.data.status[counter] === 'INACTIVE') {
                    this.modifyServiceData.push({
                      service: item.name,
                      sub_category: name,
                      is_admin: this.isAdmin(item.data.price_choose[counter]) === 'disabled',
                      category: item.data.category_name[counter],
                      category_id: item.data.category_id[counter],
                      sub_category_id: item.data.sub_category_id[counter],
                      sub_category_name: item.data.sub_category_name[counter],
                      description: item.data.description[counter],
                      base_price: item.data.price[counter],
                      base_fare: item.data.price[counter],
                      title: item.data.title[counter],
                      fare_type: item.data.fare_type[counter],
                      per_mins: item.data.per_mins[counter],
                      per_mile: item.data.per_mile[counter],
                      picture: item.data.picture[counter],
                      status: item.data.status[counter],
                      id: item.data.id[counter],
                      currency_symbol: item.data.currency_symbol[counter],
                      availability: item.data.status[counter] === 'ACTIVE',
                    })
                  }
                })
              }

            })
            this.originalModifiedData = window.SuperApp.actions.cloneDeep(this.modifyServiceData)
            this.$nextTick(() => {
              this.$refs.serviceGrid.setLocalData(this.modifyServiceData)
            })
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

<style scoped>
.data-wrapper {
  background: #6e6b7b;
  padding: 35px 15px;
  margin-right: 15px;
  border-radius: 4px;
  color: #fff;
  text-align: center;
  margin-bottom: 10px;
  cursor: pointer;
  width: 100%;
  margin-bottom: 20px;
}

.data-wrapper:hover {
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
}

.data-wrapper .form-group:last-child {
  margin: 0;
}
</style>
