<template>
  <div class="provider-my-services">
    <b-card class="show-tabs-list-mobile">
      <b-tabs pills>
        <b-tab
          v-for="(item, index) of providerServiceList"
          :key="index"
        >
          <template #title>
            <span class="d-none d-sm-inline text-uppercase">{{ item.display_name }}</span>
          </template>
          <service-transport v-if="item.admin_service === 'TRANSPORT'" />
          <service-order v-if="item.admin_service === 'ORDER'" />
          <provider-services v-if="item.admin_service === 'SERVICE'" />
          <delivery-type v-if="item.admin_service === 'DELIVERY'" />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable prefer-destructuring */

import { BCard, BTabs, BTab } from 'bootstrap-vue'
import { hideLoader, showErrorNotification, showLoader } from '@/@core/comp-functions/ui/app'
import { useProviderUi } from './useProvider'
import ServiceOrder from './my-services/ServiceOrder.vue'
import ServiceTransport from './my-services/ServiceTransport.vue'
import ProviderServices from './my-services/ProviderServices.vue'
import DeliveryType from './my-services/DeliveryType.vue'

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    ServiceOrder,
    ServiceTransport,
    ProviderServices,
    DeliveryType,
  },
  data() {
    return {
      providerServiceList: [],
      documentList: {
        all: null,
        activeTab: null,
        isAllDocumentAvailable: false,
        isActiveDocumentAvailable: false,
      },
    }
  },
  mounted() {
    this.providerServices()
  },
  methods: {
    providerServices() {
      showLoader()
      const { providerAdminServices } = useProviderUi()

      providerAdminServices()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.providerServiceList = data.responseData
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style>
.provider-my-services ul.nav.nav-pills {
  justify-content: center;
}
</style>
